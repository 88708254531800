<template>
  <div id="app">
    <AppHeader />
    <router-view />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';

export default {

  components: {
    AppHeader,
  },

};
</script>

<style lang="scss">
body {
  margin: 0;
  font-family: Font;
  overflow-y: scroll;
}
</style>
