<template>
  <div class="about">
    <section class="section black fullheight video">
      <iframe
        src="https://player.vimeo.com/video/549107180"
        width="1400"
        height="780"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen>
       </iframe>
    </section>

    <section class="section">
      <div class="content">
        <img src="@/assets/headings/what.svg" alt="">
        <div class="text">
          Mood marks are punctuation marks that express moods, feelings or tone of voice.
        </div>
      </div>
    </section>

    <section class="section yellow">
      <img src="@/assets/about/example.svg" alt="">
    </section>

    <section class="section">
      <div class="content">
        <img src="@/assets/headings/how.svg" alt="">
        <div class="text">
          Mood marks are based on a semicircle which resembles a happy smiley
          when it's turned upwards,and a sad smiley when it’s turned downwards.
          The symbol works as a full stop when written outon its own, but can
          also be combined with the exclamation mark and the question mark.-)
        </div>
      </div>
    </section>

    <section class="section black">
      <img src="@/assets/about/smileys.svg" alt="">
    </section>

    <section class="section">
      <div class="content">
        <img src="@/assets/headings/when.svg" alt="">
        <div class="text">
          You can use Mood Marks whenever you need to emphasize feelings
          or intent in written communication, while keeping your text
          elegant and easy to read.
        </div>
      </div>
    </section>

    <section class="section yellow">
      <img src="@/assets/about/conversation.svg" alt="">
    </section>

    <section class="section">
      <div class="content">
        <img src="@/assets/headings/access.svg" alt="">
        <div class="text">
          Click here to download four different fonts to your
          computer that support Mood Marks. You access the
          punctuation marks on your keyboard by using the
          ligatures described below!-)
        </div>
      </div>
    </section>

    <section class="section black">
      <img src="@/assets/about/marks.svg" alt="">
    </section>

    <section class="section">
      <textarea
        ref="textarea"
        @input="setStyle()"
        :class="fontFamily"
      >Write something here!-)</textarea>
      <!-- <div class="content">
        <div class="text">
          The smiley has solved several communicative problems, but thay have
          also contributed to a negative side effect. Today, we perceive
          messages as negative if the sender does not end the message with a
          positive emoticon or emoji.
        </div>
      </div> -->
    </section>

    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'Home',

  components: {
    AppFooter,
  },

  computed: {
    fontFamily() {
      const fonts = ['one', 'two', 'three', 'four', 'five'];
      const random = Math.floor(Math.random() * fonts.length);
      return fonts[random];
    },
  },

  methods: {
    setStyle() {
      this.$refs.textarea.style['font-variant-ligatures'] = '';

      setTimeout(() => {
        this.$refs.textarea.style['font-variant-ligatures'] = 'contextual';
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  @include section;
}

.fullheight.video {
  min-height: 100vh;
  padding: 0 $space-small;

  @include large {
    padding: 140px 40px;
    box-sizing: border-box;
  }
}

iframe {
  max-width: 100%;
}

.content img {
  max-width: 190px;

  @include large {
    max-width: none;
  }
}

.text {
  margin-left: 46px;
  margin-top: 20px;
  max-width: 900px;
  font-size: 14px;

  @include large {
    margin-left: 230px;
    margin-top: 56px;
    font-size: 36px;
  }
}

.one {
  font-family: "MoodMarks-LibreBaskerville-Regular";
}

.two {
  font-family: "MoodMarks-Lexend-Regular";
}

.three {
  font-family: "MoodMarks-Granville-Regular";
}

.four {
  font-family: "MoodMarks-Montserrat-Regular";
}

.five {
  font-family: "MoodMarks-Roboto-Regular";
}

textarea {
  max-width: 100%;
  min-height: 120px;
  background-color: transparent;
  font-size: 28px;
  padding: 12px;

  &:active, &:focus {
    outline-color: #FFEC00;
    outline-width: 1px;
  }

  @include large {
    font-size: 64px;
  }
}
</style>
