<template>
  <div class="header">
    <router-link class="logo" :to="{ name: 'Home' }">
      <img src="@/assets/icon.svg" alt="">
    </router-link>

    <div class="links" :class="color">
      <router-link class="link" :to="{ name: 'Home' }">About</router-link>
      <router-link class="link" :to="{ name: 'Marks' }">The marks</router-link>
      <router-link class="link" :to="{ name: 'Contact' }">Contact</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',

  computed: {
    color() {
      return this.$route.meta.menu;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  padding: 0 28px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include large {
    height: 140px;
    padding: 0 56px;
  }
}

.logo {
  position: fixed;
  left: 28px;
  top: 28px;

  @include large {
    left: 56px;
    top: 56px;
  }
}

.white {
  a {
    color: white;
    }
}

.link {
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 12px;

  @include large {
    font-size: 24px;
    padding: 24px;
  }

  &.router-link-exact-active {
    text-decoration: underline;
  }
}
</style>
