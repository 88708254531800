<template>
  <div class="footer">
    <div class="links">
      <p class="text" v-if="$route.name === 'Contact'">
        Mood Marks is a typography project created by Isak Hultström Swahn who
        holds a BFA in Visual Communication from Beckmans School of Design.-)
      </p>

      <template v-else>
        <router-link class="link" :to="{ name: 'Home' }">About</router-link>
        <router-link class="link" :to="{ name: 'Marks' }">The marks</router-link>
        <router-link class="link" :to="{ name: 'Contact' }">Contact</router-link>
      </template>
    </div>

    <div class="right">
      <img class="logo" src="@/assets/logo.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style lang="scss" scoped>
.footer {
  display: none;

  @include large {
    background: #FFEC00;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 156px;
  }
}

.text {
  font-size: 14px;
  padding-right: 40px;
  max-width: 600px;

  @include large {
    font-size: 18px;
  }
}

.links {
  a {
    text-transform: uppercase;
    display: block;
    font-size: 14px;
    margin: 6px 0;

    @include large {
      font-size: 18px;
    }
  }
}

.right {
  display: flex;
}

.logo {
  max-width: 108px;

  @include large {
    max-width: none;
  }
}
</style>
